.single-slide .slick-list>.slick-track>div:nth-child(2){
    display: none;
}

.page-heading, .dashboard {
    padding: 16px !important;
}

.page-heading, .sizing {
    background-color: '#00aeef' !important;
}

.displaystyle {
    display: none;
}
.center{
    text-align: center;
}
.slick-slider .slick-prev{
    z-index: 1;
}
.white-space-90{
    height: 75px;
}
.linkstyle{
    text-decoration: none;
}

.greenbox{
    width: 15px;
    height: 15px;
    background-color: #28a745;
    display: inline-block;
    margin-right: 10px;
    margin-left: 50px;
}

.whitebox{
    width: 15px;
    height: 15px;
    background-color: #fff;
    display: inline-block;
    border: 1px solid #28a745;
    margin-right: 10px;
    margin-left: 50px;
}

.sliderstylepadding{
    padding-bottom: 0px  !important;
}

.dashboard-slide .jr-card {
    padding: 20px;
    padding-bottom: 20px !important;
}

.coloring{
    background-color: #38afd6 !important;
}

.coloringSecondary{
    background-color: #89bd4c  !important;
}

.coloring3{
    background-color: #e17a2c  !important;
}
.coloring4{
    background-color: #4db6ac  !important;
}
.dash_brand .jr-card{
    padding: 0 !important;
    box-shadow: none !important;
}
@media (min-width:992px) {
    .province-chart .jr-card {
        min-height: 92.5%;
    } 
}
@media (min-width:1200px) { 
    .post-section .jr-card {
        min-height: 93.35%;
    }
    .user-expire-sect .jr-card .white-space-90{ 
        height: 23px;
    }
    .user-expire-sect .jr-card{
        min-height: 92.75%;
    }
}