@media (min-width: 768px){
    .custom-margin .col-md-2 {
        flex: 0 0 19.66667%;
        max-width: 19.66667%;
    }
}


@media (min-width: 992px)
{
    .custom-row-card .col-lg-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.table-height{
    height: 400px !important;
}

.activetab{
    border: 2px solid #3f51b5 ;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 20%);
    transition: all 0.4s ease-in-out 0.1s;
    background-color: #f04c4c;
    color:  #f04c4c;
  
}
.box-mouse-over>.card {
    cursor: pointer;
}