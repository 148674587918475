


.header-btn .MuiButton-label{
  justify-content: left !important;
}
thead.report-header {
  display: table-header-group;
}
tfoot.report-footer {
  display: table-footer-group;
}

.customcolorindarkmode {
  background-color: #fff;
}
.header-info button {
  color: #000;
}
.invertor-diamention {
  width: 60%;
} 

.pageNo{
  float: right;
  bottom: 0;
  margin-bottom: 0;
}

@media print {
  
  .report-content {
    counter-reset: page;
  }
   
  .pageNo::after{
    counter-increment: page;
    content: "Page " counter(page) " of 10";
  }
  .project-table{
    min-height: 260px;
    max-height: 260px;
    overflow-y: hidden;
  }
  .accessory-sect {
    min-height: 760px;
    max-height: 760px;
  }
    .structure-img {
      margin-top: 140px;
      margin-bottom: 250px;
    }
    .manual-img {
      margin-top: 200px;
      margin-bottom: 450px;
    }
    .controller-diameter{
      min-height: 790px;
      max-height: 790px;
      margin-bottom: 10px;
    }
    .invertor-diamention {
      /* width: 100%; */
      max-height: 790px;
     
    }
    .controller-table{
      min-height: 450px;
      max-height: 450px;
    }
    .invertor-image {
      max-height: 450px;
    }
    .wiring-img {
      max-height: 1240px;
      margin-bottom: 20px;
    }
    .area-section{
      min-height: 1250px;
      max-height: 1250px;
    }
    .genral-layout-img{
      margin-bottom: 0px;
      /* max-height: 98%; */
    }    
    .sizing-img{
      margin-bottom: 0px;
      
    }
    .sizing-img-section img{
       max-height: 1230px; 
    }
    .pump-diameter {
      min-height: 770px;
      max-height: 770px;
      margin-bottom: 20px;
    }
    .solar-image{
      max-height: 500px;
    }
    .solar-diameter {
      min-height: 750px;
      max-height: 750px;
    }
    /* .solar-diamention{
      min-height: 600px;
      max-height: 900px;
    } */

    
  }

  .report-header .btn-group-vertical button{
    cursor: default;
  }

/* start for large screen size */
/* @media print{ */
  @media screen and (min-width: 1650px) {
    .graph-section{
      max-width: 1100px !important;
    }
  }
  
/* } */
/* end for large screen size */
@media (max-width: 991px) {
  .project-summary, .table-pro{
    overflow-x: scroll;
  }
}