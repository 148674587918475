.customeCard .jr-card{
    box-shadow: none !important;
    padding:0px;
}
.thead-tr{
    background-color: #3f51b5;
    color: #f2f2f2;
}
.activebrand{
    border: 2px solid #3f51b5;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 20%);
    transition: all 0.4s ease-in-out 0.1s;
    background-color: #f2f2f2;
  
}

/* .activebrand:hover{
   
} */
/* .brand-step-0>span>div{
    background: rgb(0,121,254);
    background: linear-gradient(90deg,rgba(0,121,254,1) 0%, rgba(5,198,253,1) 100%);
}
.brand-step-1>span>div{
    background: rgb(104,44,48);
    background: linear-gradient(0deg, rgba(104,44,48,1) 0%, rgba(165,146,120,1) 40%, rgba(53,110,216,1) 77%, rgba(30,85,177,1) 100%);
}
.brand-step-2>span>div{
    background: rgb(221,221,221);
    background: linear-gradient(158deg, rgba(221,221,221,1) 0%, rgba(64,64,64,1) 100%);
} */

.custompargraph{
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 50px;
}
.preview-section{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.preview-section>.MuiListItem-button {
    cursor: auto;
    background-color: rgba(0, 0, 0, 0.04);
}
.customdisply{
    display: inline;
}

/* image fade-in and out */
.fade-in{
    animation: fadeIn 3s;
}
.fade-incustom{
    animation: fadeIncustom 2s;
}

@keyframes fadeIn {
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }
}


@keyframes fadeIncustom {
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }
}

/* .imagebrandhieght{
    height: 100px;
} */
/* .img_solar_brand_hieght{
    height: 90px;
} */
/* .solar>.brand-logo{
    padding-bottom: 90%;
} */
.customslider{
    height: 118px;
}

/* country flag */
.customflag{
    width: 20px;
    height: 20px;
}

/* custom widget hover */
.customWidgetHover:hover{
    box-shadow: 0px 8px 10px rgb(0 0 0 / 20%);
    transition: all 0.4s ease-in-out 0.1s;
    background-color: #2236f2;
    border-radius: 5%;
}
@media (max-width: 430px){
    .project_bas_field .btn {
        padding: 0.68rem !important;
    }
}
@media (max-width: 991px){
    .brandrow-step{
        padding-top: 10px !important;
    }
}
@media (max-width: 767px){
    .col-md-5.pr-0{
        padding-right: 15px !important;
    }
    .col-md-5.pl-0{
        padding-left: 15px !important;
    }
}
.m-a{
    margin: auto !important;
}
@media (max-width: 1360){
    .rounded {
        max-height: 450px;
    }
}
.graph-img-md{
    max-height: 450px;
    margin: auto;
}

@media (max-width: 991){
    .graph-img-md{
        max-height: 330px !important;
    }
}
.graph-img-md img{
    max-height: inherit;
}
.accessory-wrapper{
    max-height: 390px;
    overflow-y: auto;
}
.truerSPrSection1{
    /* max-height: 690px; */
    background-color: #f7f7f7;
}
.dark-theme .truerSPrSection1{
    background-color: #38424B !important;
}
.row_ustify{
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.accessory-item-sect{
    min-height: 477px;
}
.step3-hieght{
    min-height: 477px;
}

.step3-hieght>div{
    margin: auto;
}
.main-sptem1 .MuiStepIcon-root.MuiStepIcon-active, .main-sptem1 .MuiStepIcon-root.MuiStepIcon-completed {
    color: #ec5144;
}
.main-sptem2 .MuiStepIcon-root.MuiStepIcon-active, .main-sptem2 .MuiStepIcon-root.MuiStepIcon-completed {
    color: #47ab47;
}
.inputAdornment .MuiOutlinedInput-adornedEnd {
    padding-right: 2px;
}
.inputAdornment .MuiInputAdornment-positionEnd{
    margin-left: 2px ;
    opacity: 0.54;
}
.inputAdornment .cursorPointer .MuiSvgIcon-root{
    width: 20px;
}
.MuiSnackbarContent-root {
    color: black !important;
    background-color: rgb(244 244 247) !important;
    max-width: 500px !important;
}
.dark-theme .brand-logo span{
    color: black !important;
}

@media (min-width: 1300px){
    .input-space{
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }
}
@media (min-width: 1440px){
    .input-space{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
}
@media (min-width: 1660px){
    .input-space{
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }
    .sizeing-heading{
        display: block !important;
    }
}
@media (min-width: 1920px){
    .input-space{
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }
    .sizeing-heading{
        display: block !important;
        font-size: 20px;
        padding: 10px;
    }
}
.m-success-btn{
    color: #fff !important;
    background-color: #47ab47 !important;
}