.customelogobrand{
    border: 1px solid #fcfcfc;
    background-color: #2196f3;
    border-radius: 0.5rem;
    margin-bottom: 20px;
    padding-bottom: 72%;
    height: 176px;
    margin-top: 20px;

}
.app-login-form .password label, .app-login-form .username label, .username .MuiInputBase-input, .password .MuiInputBase-input, .password .MuiIconButton-edgeEnd{ 
 color: #fff;
}
.app-login-form .MuiInput-underline:before{
    border-bottom: 2px solid #dcd6d67a !important;
}

@media (min-width: 577px) {
    .app-login-main-content{
        height: auto !important;
    }
}
@media (max-width: 576px) {
    .rmb{
        margin-bottom: 1.5rem!important;
    }
    .app-logo-content img{
        max-height: 250px;
    }
    
}
